import React from "react";
import { DownloadIcons } from "../components/DownloadIcons";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";

const Page = () => {
    return (
        <>
            <SEO title={"Home"} lang={"en"} meta={[]} />
            <Layout label={"Match 3 City Building Game"}>
                <DownloadIcons playStoreUrl={"https://play.google.com/store/apps/details?id=com.likeaphoton.tfsc.twt.match3.slice"} appStoreUrl={"https://apps.apple.com/us/app/fairy-world-quest/id1484760100?ls=1"} />
            </Layout>
        </>
    );
};


export default Page;
