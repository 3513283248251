import React, { PropsWithChildren } from "react";

interface IProps {
    playStoreUrl: string;
    appStoreUrl: string;
}

export const DownloadIcons = (props: PropsWithChildren<IProps>) => {
    const { playStoreUrl, appStoreUrl } = props;

    return <div className={"download-icons"}>
        <a className={"google"} href={playStoreUrl} target={"_blank"}>Get it on Google Play</a>
        <a className={"apple"} href={appStoreUrl} target={"_blank"}>Download on the App Store</a>
    </div>;
}
